import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Button, IconButton, InputAdornment } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../component/theme';
import { toast } from "react-toastify";

const drawerWidth = 240;

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    flexGrow: 1,
   
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
      
    }),
}));

export default function Create_Institutes() {
    
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNo: '',
        password: '',
        batchlimit: '',  
        studentLimit: '',
    });

    const [showPassword, setShowPassword] = useState(false);
   

    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        
        // Perform validation
        if (!formData.name || !formData.email || !formData.contactNo || !formData.password || !formData.studentLimit || !formData.batchlimit) {
            toast.error("Please fill all required fields.");
            return;
        }

        

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(formData.email)) {
            toast.error("Please enter a valid email address.");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/createinstitutes`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ ...formData }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log('Success:', data);
            toast.success("Institute created successfully!");

            setFormData({
                name: '',
                email: '',
                contactNo: '',
                password: '',
                batchlimit: '',  
                studentLimit: '',
            });
        } catch (error) {
            console.error('Error:', error);
            toast.error("There was an error creating the institute. Please try again.");
        }
    };

    const handleCancel = () => {
        setFormData({
            name: '',
            email: '',
            contactNo: '',
            password: '',
            batchlimit: '',
            studentLimit: '',
        });
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
               
                <Main >
                    <Typography sx={{ fontWeight: 'bold', mb: 0 }}>Create Institutes</Typography>
                    <Box sx={{ width: 1070,border: 2, borderRadius: 4 }}>
                        <form onSubmit={handleSubmit}>
                            <Box sx={{ display: "flex", gap: 3 }}>
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    label="Institute Name"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleFormChange}
                                    sx={{ margin: 2, width: "28%" }}
                                />
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    label="Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleFormChange}
                                    sx={{ margin: 2, width: "28%" }}
                                />
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    label="Contact No"
                                    name="contactNo"
                                    value={formData.contactNo}
                                    onChange={handleFormChange}
                                    inputProps={{ minLength: 10, maxLength: 10, pattern: "[0-9]*", inputMode: "numeric" }}
                                    sx={{ margin: 2, width: "28%" }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", gap: 3 }}>
                                <TextField
                                    type={showPassword ? "text" : "password"}
                                    variant="outlined"
                                    size="small"
                                    label="Create Password"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleFormChange}
                                    sx={{ margin: 2, width: "28%" }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                    autoComplete="new-password"  // Added correct autocomplete attribute
                                />
                                <TextField
                                    
                                    variant="outlined"
                                    size="small"
                                    label="Batch Limit"
                                    name="batchlimit"
                                    value={formData.batchlimit}  // Added confirm password value handling
                                    onChange={handleFormChange}
                                    sx={{ margin: 2, width: "28%" }}
                                    
                                    
                                />
                                <TextField
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    label="Student Limit"
                                    name="studentLimit"
                                    value={formData.studentLimit}
                                    onChange={handleFormChange}
                                    sx={{ margin: 2, width: "28%" }}
                                />
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: 'flex-end', margin: 2 }}>
                                <Button type="submit" variant="contained" sx={{ mr: 2 }}>Submit</Button>
                                <Button variant="contained" onClick={handleCancel}>Cancel</Button>
                            </Box>
                        </form>
                    </Box>
                </Main>
            </Box>
        </ThemeProvider>
    );
}
