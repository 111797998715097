import React from 'react';
import './App.css';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import Login from './component/Login';
import Forgotpassword from './component/Forgot-password';
import Home from './component/Home';
import Student from './pages/Student';
import CandidateReport from './pages/Candidate-Report';
import ViewBatch from './pages/View-Batch';
import CreateBatch from './pages/Create-Batch';
import ViewInstitutes from './pages/View-Institute';
import Download from './pages/Download';
import CreateInstitutes from './pages/Create-Institutes';
import Paymentdetails from './pages/Payment-details';
import ViewPaymentdetails from './pages/View-Paymentdetails';
import PermissionManagement from './pages/Access-permission';
import Sidenav from './component/Sidenav';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Forgot_password" element={<Forgotpassword />} />
        <Route  element={<Sidenav />} >
        <Route path="/Home" element={<Home />} /> 
        <Route path="/Student" element={<Student />} />
        <Route path="/Student/:id/:studentname/:fathername/:dob/:contact/:batchid/:aadhaarno/:fingarprint1/:fingarprint2/:fingarprint3/:fingarprint4/:fingarprint5?" element={<Student />} />
        <Route path="/Candidate_Report" element={<CandidateReport />} />
        <Route path="/View_Batch" element={<ViewBatch />} />
        <Route path="/View_Institutes" element={<ViewInstitutes />} />
        <Route path="/Create_Institutes" element={<CreateInstitutes />} />
        <Route path="/Create_Batch" element={<CreateBatch />} />
        <Route path="/Create_Batch/:batch_id/:batch_name/:student_count/:startdate/:enddate?" element={<CreateBatch />} />
        <Route path="/Payment_details" element={<Paymentdetails />} />
        <Route path="/View_Paymentdetails" element={<ViewPaymentdetails />} />
        <Route path="/PermissionManagement" element={<PermissionManagement />} />
        <Route path="/Download" element={<Download />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
