import React from "react";
import { TextField, InputAdornment, IconButton, Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../component/theme';

const Main = styled(Box)(() => ({
  flexGrow: 1,
  backgroundColor: 'black', // Specify any other desired styles here

  
}));
export default function Candidate_Report() {
  
    const [course, setCourse] = React.useState('');
    const [searchQuery, setSearchQuery] = React.useState("");

    const handleChange = (event) => {
        setCourse(event.target.value);
    };

    const handleSearch = () => {
        console.log("Searching for:", searchQuery);
        // Add your search logic here
      };

    return (
      <ThemeProvider theme={theme}>
        <Box sx={{ display: 'flex' }}>
            
            <Main >
            <Typography sx={{ fontWeight: 'bold' }}>Search Candidate Report List</Typography>
                    <Box variant="outlined" sx={{ width: 'auto', border: 2, borderRadius: 4 }}>
                    
                    <FormControl sx={{ m: 2, minWidth: 200 }} >
      <InputLabel id="demo-simple-select-helper-label" >Select Job Role</InputLabel>
      <Select
        size="small"
        labelId="demo-simple-select-helper-label"
        id="demo-simple-select-helper"
        value={course}
        label="Select Course"
        onChange={handleChange}
      >
        <MenuItem value={10}>Ten</MenuItem>
        <MenuItem value={20}>Twenty</MenuItem>
        <MenuItem value={30}>Thirty</MenuItem>
      </Select>
      <Button variant="contained" color="primary" sx={{ margin: 1 }} onClick={handleSearch}>
        Search Candidate Details
      </Button>
    </FormControl>
      
      </Box>

      <Box variant="outlined" sx={{ marginTop:5,border: 2, borderRadius: 4 }}>
      <Typography sx={{ fontWeight: 'bold',margin:1,mb:-1 }}>Candidate Report List</Typography>
      <TextField
        variant="outlined"
        placeholder="Search By Name,Contact No or Aadhaar No"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        sx={{ width: 400, margin:2 }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={handleSearch}>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />


</Box>
            </Main>
        </Box>
        </ThemeProvider>
    );
}
