import React, { useState } from "react";
import Box from '@mui/material/Box';
import Sidenav from '../component/Sidenav';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from '@mui/material/Button';
import StudentList from "./StudentList";
import { toast } from "react-toastify";
import theme from '../component/theme';
import { useParams } from 'react-router-dom';
import Cookies from "js-cookie";

const drawerWidth = 240;

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    flexGrow: 1,
    
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
     
    }),
}));

const FingerprintBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: '1px solid #ccc',
    borderRadius: 8,
    padding: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    width: 150,
    boxShadow: theme.shadows[3],
}));

export default function Student() {
   
    const { id,studentname, fathername,dob,contact,batchid,aadhaarno,fingarprint1,fingarprint2,fingarprint3,fingarprint4,fingarprint5} = useParams();
    const [studentName, setStudentName] = useState(studentname || '');
    const [fathersName, setFathersName] = useState(fathername || '');
    const [phoneNumber, setPhoneNumber] = useState(contact || '');
    const [batch, setBatch] = useState(batchid || '');
    const [aadhaar, setAadhaar] = useState(aadhaarno || '');
    const [fingerprints, setFingerprints] = useState([null, null, null, null, null]);
    // const formatteddob = dob ? new Date(dob).toISOString().slice(0, 10) : '';
    const [date, setDate] = useState( null);

    const handlePhoneChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value.length <= 10) {
            setPhoneNumber(value);
        }
    };

    const handleAadhaarChange = (event) => {
        const value = event.target.value;
        if (/^\d*$/.test(value) && value.length <= 12) {
            setAadhaar(value);
        }
    };

    const handleInputs = (setter) => (event) => {
        setter(event.target.value);
    };

    // Handler for file upload for fingerprints
    const handleFingerprintUpload = (index) => (event) => {
        const file = event.target.files[0];
        if (file) {
            setFingerprints((prevFingerprints) => {
                const newFingerprints = [...prevFingerprints];
                newFingerprints[index] = file; // Store the file in the respective slot
                return newFingerprints;
            });
            toast.success(`Fingerprint ${index + 1} uploaded successfully`);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const token = Cookies.get("login_jwtToken");
       // console.log(token,"from student")

        if (phoneNumber.length !== 10) {
            toast.error("Phone number must be exactly 10 digits.");
            return;
        }
        if (aadhaar.length !== 12) {
            toast.error("Aadhaar number must be exactly 12 digits.");
            return;
        }

        if (fingerprints.some((finger) => finger === null)) {
            toast.error("All fingerprint files must be uploaded before submitting.");
            return;
        }

        const formData = new FormData();
        formData.append('studentid', id || '');
        formData.append('studentname', studentName);
        formData.append('fathername', fathersName);
        formData.append('dob', date ? date.format('YYYY-MM-DD') : '');
        formData.append('contact', phoneNumber);
        formData.append('batch', batch);
        formData.append('aadhaarno', aadhaar);

        fingerprints.forEach((fingerprint, index) => {
            formData.append(`finger${index + 1}`, fingerprint);
            
        });

        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/createstudent`, {
                method: 'POST',
                headers: {
                     'authorization': `Bearer ${token}`,
                  },
                body: formData,
            
            });
           

            if (!response.ok) {
                throw new Error('Failed to create student');
            }

            const data = await response.json();

            if (data.error) {
                toast.error(data.error);
            } else {
                toast.success('Student created successfully');
                setStudentName('');
                setFathersName('');
                setPhoneNumber('');
                setBatch('');
                setAadhaar('');
                setDate(null);
                setFingerprints([null, null, null, null, null]);
            }
        } catch (error) {
            console.error('Error:', error);
            toast.error(error.message);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                
                <Main>
                    <Typography sx={{ fontWeight: 'bold' }}>Manage Your Students</Typography>
                    <Box variant="outlined" sx={{ width: 1070, border: 2, borderRadius: 4 }}>
                        <Box
                            component="form"
                            onSubmit={handleSubmit}
                            sx={{ '& > :not(style)': { m: 1, width: '320px' } }}
                            noValidate
                            autoComplete="off"
                        >
                            <TextField size="small" type="text" value={studentName} onChange={handleInputs(setStudentName)} label="Student Name" variant="outlined" />
                            <TextField size="small" type="text" value={fathersName} onChange={handleInputs(setFathersName)} label="Father's Name" variant="outlined" />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="Date of Birth" value={date} onChange={setDate} renderInput={(params) => <TextField {...params} size="small" />} />
                            </LocalizationProvider>
                            <TextField size="small" type="text" value={phoneNumber} onChange={handlePhoneChange} label="Contact No." variant="outlined" inputProps={{ maxLength: 10, inputMode: "numeric" }} />
                            <TextField size="small" value={batch} onChange={handleInputs(setBatch)} label="Batch" variant="outlined" />
                            <TextField size="small" value={aadhaar} onChange={handleAadhaarChange} label="Aadhaar No." variant="outlined" inputProps={{ maxLength: 12, inputMode: "numeric" }} />

                            <Box sx={{ display: "flex", gap: 5 }}>
                                {fingerprints.map((_, index) => (
                                    <FingerprintBox key={index}>
                                        <Typography gutterBottom>Finger {index + 1}</Typography>
                                        <Button
                                            variant="contained"
                                            component="label"
                                        >
                                        Upload
                                            <input
                                                type="file"
                                                hidden
                                                accept="image/*"
                                                onChange={handleFingerprintUpload(index)}
                                            />
                                        </Button>

                                        <Typography variant="caption">{fingerprints[index] ? fingerprints[index].name : 'No file uploaded'}</Typography>

                                        <Button variant="contained" >
                                            {id ? 'Captured' : 'Capture'}
                                        </Button>
                                    </FingerprintBox>
                                ))}
                            </Box>
                            <Button variant="contained" type="submit">
                             {id ? 'Update' : 'Submit'}
                                
                                </Button>
                        </Box>
                    </Box>
                    <Box variant="outlined" sx={{ mt: 2, width: 'auto', border: 2, borderRadius: 4 }}>
                        <StudentList />
                    </Box>
                </Main>
            </Box>
        </ThemeProvider>
    );
}
