import React from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { Button } from "@mui/material";
import { toast } from 'react-toastify';
import theme from '../component/theme'; 
import { ThemeProvider } from '@mui/material/styles';


const drawerWidth = 240;

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    flexGrow: 1,
 
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
      
    }),
}));

export default function Payment_details() {
    const [batchNo, setBatchNo] = React.useState('');
    const [reference, setReference] = React.useState('');
    const [state, setState] = React.useState('');
    const [selectedFile, setSelectedFile] = React.useState(null);

    const handleInputs = (setter) => (event) => {
        setter(event.target.value);
    };

    const handleCancel = () => {
      
        setBatchNo('');
        setReference('');
        setState('');
        setSelectedFile(null);
    };

    const handleFileChange = (event) => {
        
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async () => {
        if (!batchNo) {
            toast.error('Please Fill Batch No.');
            return;
        }
        
        if (!reference) {
            toast.error('Please Fill Reference No.');
            return;
        }
        
        if (!state) {
            toast.error('Please Fill State');
            return;
        }
        
        if (!selectedFile) {
            toast.error('Please Select Payment File');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile); // Append the selected file
        formData.append('batchNo', batchNo); // Append batch number
        formData.append('reference', reference); // Append reference
        formData.append('state', state); // Append state


        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/paymentdetails`, {
                method: 'POST',
                body: formData,
            });

            const data = await response.json();
            console.log('Response:', data);
            if (data.error) {
                toast.error(data.error.message);
            } else if (data.success) {
                handleCancel(); 
                toast.success('Payment Receipt Uploaded successfully');
            } else {
                toast.error('Internal Server Error');
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
               
                <Main >
                    <Typography sx={{ fontWeight: 'bold', mb: 0 }}>Upload Payment Details</Typography>
                    <Box variant="outlined" sx={{ width: 1070, border: 2, borderRadius: 4 }}>  
                        <Box display="flex">
                            <TextField 
                                size="small"
                                type="text" 
                                value={batchNo} 
                                id="batchNo" 
                                label="Institute Name"
                                onChange={handleInputs(setBatchNo)}  
                                variant="outlined" 
                                sx={{ margin: 2 }}
                            />
                            <TextField 
                                size="small"
                                type="text" 
                                value={reference} 
                                id="reference" 
                                label="Transaction Id"
                                onChange={handleInputs(setReference)}  
                                variant="outlined" 
                                sx={{ margin: 2 }}
                            />
                            <TextField 
                                size="small"
                                type="text" 
                                value={state} 
                                id="state" 
                                label="City / State"
                                onChange={handleInputs(setState)}  
                                variant="outlined" 
                                sx={{ margin: 2 }}
                            />
                           </Box>
                           <Box sx={{marginLeft:2}}>
                            <TextField
                                accept=".pdf" // Limit file types
                                style={{ display: 'none' }}
                                id="file-input"
                                type="file"
                                onChange={handleFileChange}
                            />
                            <label htmlFor="file-input">
                                <Button variant="contained" component="span" sx={{ mt: 2 }}>
                                    Choose Payment File
                                </Button>
                            </label>

                            {selectedFile && (
                                <Typography variant="body1">
                                 <b>Selected File Name:</b> {selectedFile.name}
                                </Typography>
                            )}
                        </Box>
                        <Box align="right" sx={{ margin: 2, display: 'flex', justifyContent: 'flex-end' }}>
                            <Button sx={{ mr: 1 }} onClick={handleSubmit} type="submit" variant="contained" color="primary">Submit</Button>
                            <Button onClick={handleCancel} variant="contained" color="primary">Cancel</Button>
                        </Box>
                    </Box>
                </Main>
            </Box>
        </ThemeProvider>
    );
}
