import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { toast } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../component/theme";
import { useNavigate } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import * as XLSX from "xlsx";
import { FileDownload } from "@mui/icons-material";
import Icon from '@mdi/react';
import { mdiMicrosoftExcel } from '@mdi/js';

const drawerWidth = 240;

const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  flexGrow: 1,
  padding: theme.spacing(1),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  marginLeft: `-${drawerWidth}px`,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),

  }),
}));

export default function View_Batch() {
  const [searchText, setSearchText] = useState("");
  const [rows, setRows] = useState([]); 
  const [allRows, setAllRows] = useState([]); 
  const [page, setPage] = useState(0); // State for current page
  const [rowsPerPage, setRowsPerPage] = useState(5); // State for rows per page
  const [openslide, setOpenslide] = useState(false);
  const [selectedBatchId, setSelectedBatchId] = useState(null); // Track the batch id for disabling
  const [Selectedstatus, setSelectedstatus] = useState(null);
  const [sortDirection, setSortDirection] = useState("asc"); // State to hold sort direction
  const [sortColumn, setSortColumn] = useState("batch_id"); // State to hold the current sort column
  const navigate = useNavigate();

  // Fetch API on component load
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/viewbatch`);
        const data = await response.json();
        if (data.success && Array.isArray(data.success)) {
          setAllRows(data.success); // Store original rows
          setRows(data.success); // Set initial rows to be displayed
        } else {
          toast.error("Unexpected API response");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Error fetching data");
      }
    };
    fetchData();
  }, []);

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(allRows); // Convert JSON data to sheet
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Batches"); // Add sheet to workbook
    XLSX.writeFile(workbook, "BatchData.xlsx"); // Download the file
  };

  // Handle search query change
  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchText(value);

    // Filter rows based on search input from the original dataset
    const filteredRows = allRows.filter((row) => {
      return (
        (row.batch_name &&
          row.batch_name.toLowerCase().includes(value.toLowerCase())) ||
        (row.student_count && row.student_count.toString().includes(value))
      );
    });

    setRows(filteredRows); // Update rows with filtered data
    setPage(0); // Reset to the first page on search
  };

  // Sort function
  const handleSort = (column) => {
    const newDirection = sortDirection === "asc" ? "desc" : "asc";
    const sortedRows = [...rows].sort((a, b) => {
      if (typeof a[column] === "string") {
        return newDirection === "asc"
          ? a[column].localeCompare(b[column])
          : b[column].localeCompare(a[column]);
      }
      return newDirection === "asc"
        ? a[column] - b[column]
        : b[column] - a[column];
    });
    setSortDirection(newDirection);
    setSortColumn(column);
    setRows(sortedRows);
  };

  // Edit function
  const handleEdit = (batch_id, batch_name,student_count,startdate,enddate) => {
    navigate(`/Create_Batch/${batch_id}/${batch_name}/${student_count}/${startdate}/${enddate}`);
  };

  // Open confirmation dialog
  const handleClickOpen = (batch_id,status) => {
    setSelectedBatchId(batch_id);
    setSelectedstatus(status);
    
    setOpenslide(true);
  };

  // When user agrees to disable
  const handleAgree = () => {
    if (selectedBatchId) {
      handleDelete(selectedBatchId); // Pass the selected batch id to the delete function
      setOpenslide(false);
    }
  };

  // When user disagrees
  const handleDisagree = () => {
    setSelectedBatchId(null); // Clear the selected batch id when dialog closes
    setOpenslide(false);
  };

// Disable/Enable function
const handleDelete = async (selectedBatchId) => {
  try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/viewbatch/batchstatus`, {
          method: "POST",
          headers: {
              "Content-Type": "application/json",
          },
          body: JSON.stringify({ batchid: selectedBatchId,
            status: Selectedstatus
          }),
      });

      const data = await response.json();

      if (data.error) {
          toast.error("Unexpected Error");
      } else {
          // Show a success message based on new status
          const message = data.newStatus === 1 
              ? "Batch has been Disabled!" 
              : "Batch has been Enabled!";
          toast.success(message);

          // Update the status in allRows without removing the batch
          const updatedRows = allRows.map((row) =>
              row.batch_id === selectedBatchId 
                  ? { ...row, status: data.newStatus } 
                  : row
          );
          setAllRows(updatedRows);
          setRows(updatedRows);  // Update the rows displayed

      }
  } catch (error) {
      toast.error(error.message);
  }
};


  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page on rows per page change
  };

  // Calculate the rows to be displayed on the current page
  const displayedRows = rows.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex" }}>
          <Main>
          <Typography sx={{ fontWeight: "bold", color: "white", mb: -5 }}>
            Manage Your Batches
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-end", mb: 1 }}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              onClick={() => navigate("/Create_Batch")}
            >
              +CREATE NEW
            </Button>
          </Box>
          <Box sx={{ border: 2, width: "auto", borderRadius: 4 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: 2,
              }}
            >
              {/* Download Button */}
              <Button
               
                
                onClick={handleDownloadExcel}
                startIcon={<Icon path={mdiMicrosoftExcel} size={1} color="green" />}
                
              >
                <FileDownload />
              </Button>

              {/* Search Bar */}
              <TextField
                size="small"
                label="Search Batches"
                variant="outlined"
                value={searchText}
                onChange={handleSearch}
              />
            </Box>
            {/* Dialog for confirmation */}
            <Dialog
              open={openslide}
              onClose={handleDisagree}
              aria-labelledby="confirmation-dialog-title"
              aria-describedby="confirmation-dialog-description"
            >
              <DialogTitle id="confirmation-dialog-title">
                Confirmation
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="confirmation-dialog-description">
                  Are you sure you want to proceed?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDisagree} color="primary">
                  Disagree
                </Button>
                <Button onClick={handleAgree} color="primary" autoFocus>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>

            {/* Table to display batches */}
            <TableContainer component={Paper} sx={{ maxHeight: 350 }}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        cursor: "pointer",
                        width: "15%",
                      }}
                      onClick={() => handleSort("batch_id")}
                    >
                      ID{" "}
                      {sortColumn === "batch_id" &&
                        (sortDirection === "asc" ? " ▲" : " ▼")}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        cursor: "pointer",
                        width: "15%",
                      }}
                      onClick={() => handleSort("batch_name")}
                    >
                      Batch{" "}
                      {sortColumn === "batch_name" &&
                        (sortDirection === "asc" ? " ▲" : " ▼")}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        cursor: "pointer",
                        width: "15%",
                      }}
                      onClick={() => handleSort("student_count")}
                    >
                      Student{" "}
                      {sortColumn === "student_count" &&
                        (sortDirection === "asc" ? " ▲" : " ▼")}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        cursor: "pointer",
                        width: "15%",
                      }}
                      onClick={() => handleSort("startdate")}
                    >
                      Startdate{" "}
                      {sortColumn === "startdate" &&
                        (sortDirection === "asc" ? " ▲" : " ▼")}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: 20,
                        fontWeight: "bold",
                        cursor: "pointer",
                        width: "15%",
                      }}
                      onClick={() => handleSort("enddate")}
                    >
                      Enddate{" "}
                      {sortColumn === "enddate" &&
                        (sortDirection === "asc" ? " ▲" : " ▼")}
                    </TableCell>
                    <TableCell
                      style={{ fontSize: 20, fontWeight: "bold", width: "15%" }}
                    >
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {displayedRows.map((row) => (
                    <TableRow key={row.batch_id}>
                      <TableCell>{row.batch_id}</TableCell>
                      <TableCell>{row.batch_name}</TableCell>
                      <TableCell>{row.student_count}</TableCell>
                      <TableCell>
                        {row.startdate &&
                        row.startdate !== "0000-00-00 00:00:00"
                          ? new Date(row.startdate).toLocaleDateString()
                          : "N/A"}
                      </TableCell>
                      <TableCell>
                        {row.enddate && row.enddate !== "0000-00-00 00:00:00"
                          ? new Date(row.enddate).toLocaleDateString()
                          : "N/A"}
                      </TableCell>

                      <TableCell>
                        <Box display="flex" gap={1}>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() =>
                              handleEdit(row.batch_id, row.batch_name, row.student_count,row.startdate,row.enddate)
                            }
                          >
                            Edit
                          </Button>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => handleClickOpen(row.batch_id,row.status)}
                          >
                           {row.status === 0 ? "Disabled" : "Enabled"}
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Box>
        </Main>
      </Box>
    </ThemeProvider>
  );
}
