import React, { useState } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Button, TextField } from "@mui/material";
import { toast } from 'react-toastify';
import theme from '../component/theme'; 
import { ThemeProvider } from '@mui/material/styles';
import { useParams } from 'react-router-dom';


const drawerWidth = 240;

const Main = styled('main', {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
     
    }),
}));

export default function Create_Batch() {
    const { batch_id, batch_name, student_count,startdate ,enddate} = useParams();
    const [batchName, setBatchName] = useState(batch_name || '');
    const formattedStartDate = startdate ? new Date(startdate).toISOString().slice(0, 10) : '';
    const [startDate, setStartDate] = useState(formattedStartDate || '');
    const formattedenddate = enddate ? new Date(enddate).toISOString().slice(0, 10) : '';
    const [endDate, setEndDate] = useState(formattedenddate || '');
    const [studentLimit, setStudentLimit] = useState(student_count || '');


    

    const handleInputs = (setter) => (event) => {
        setter(event.target.value);
    };

    const handleCancel = (event) => {
        event.preventDefault();
        setBatchName('');
        setStartDate('');
        setEndDate('');
        setStudentLimit('');
        
    };

    const handleSubmit = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/createbatch`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    batch_id:batch_id,
                    createbatch: batchName,
                    startDate,
                    endDate,
                    studentLimit,
                }),
            });

            const data = await response.json();
            console.log(data)
            if (data.error) {
                toast.error(data.error);
            } else if (data.success) {
                setBatchName('');
                setStartDate('');
                setEndDate('');
                setStudentLimit('');
                toast.success(data.success);
            } else {
                toast.error('Internal Server Error');
            }
        } catch (error) {
            console.error('Error:', error.message);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ display: 'flex' }}>
                
                <Main >
                    <Typography sx={{ fontWeight: 'bold', mb: 0 }}>Create Your Batch</Typography>
                    <Box variant="outlined" sx={{ width: "auto", border: 2, borderRadius: 4, padding: 2 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                            <TextField
                                size="small"
                                type="text"
                                value={batchName}
                                id="outlined-basic"
                                label="Batch Name"
                                onChange={handleInputs(setBatchName)}
                                variant="outlined"
                                sx={{ margin: 2 }}
                            />
                            <TextField
                                size="small"
                                type="date"
                                value={startDate}
                                id="start-date"
                                // label="Start Date"
                                onChange={handleInputs(setStartDate)}
                                variant="outlined"
                                sx={{ margin: 2 }}
                            />
                            <TextField
                                size="small"
                                type="date"
                                value={endDate}
                                id="end-date"
                                // label="End Date"
                                onChange={handleInputs(setEndDate)}
                                variant="outlined"
                                sx={{ margin: 2 }}
                            />
                            <TextField
                                size="small"
                                type="text"
                                value={studentLimit}
                                id="student-limit"
                                label="Student Limit"
                                onChange={handleInputs(setStudentLimit)}
                                variant="outlined"
                                sx={{ margin: 2 }}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, marginTop: 2 }}>
                            <Button
                                onClick={handleSubmit}
                                type ="submit"
                                variant="contained"
                                color="primary"
                            >
                               {batch_name ? 'Update' : 'Submit'}
                            </Button>
                            <Button
                                onClick={handleCancel}
                                variant="contained"
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                </Main>
            </Box>
        </ThemeProvider>
    );
}